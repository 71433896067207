import * as React from 'react';
import classNames from 'classnames';
import { SkinScreenWidthContainerProps } from '../../../SkinScreenWidthContainer';
import skinsStyle from './styles/skins.scss';

const ShadowBottomScreen: React.FC<SkinScreenWidthContainerProps> = ({
  wrapperProps: {
    id,
    tagName = 'div',
    eventHandlers,
    className: propsClassName,
  },
  children,
  animation,
}) => {
  const SemanticElement = tagName as keyof JSX.IntrinsicElements;

  return (
    <SemanticElement
      id={id}
      className={classNames(
        skinsStyle.ShadowBottomScreen,
        animation && skinsStyle[animation],
        propsClassName,
      )}
      {...eventHandlers}
    >
      <div className={skinsStyle.centerArea}>
        <div className={`${skinsStyle.ln} ${skinsStyle.left}`}></div>
        <div className={`${skinsStyle.ln} ${skinsStyle.center}`}></div>
        <div className={`${skinsStyle.ln} ${skinsStyle.right}`}></div>
      </div>
      <div className={skinsStyle.screenWidthBackground}>
        <div className={skinsStyle.bg}></div>
      </div>
      <div className={skinsStyle.centeredContent}>
        <div className={skinsStyle.centeredContentBg}></div>
        <div className={skinsStyle.inlineContent}>{children}</div>
      </div>
    </SemanticElement>
  );
};

export default ShadowBottomScreen;
